
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import CardOpeningHistoryAPI from "@/common/api/cardOpeningHistory";
export const pageApiList = getPageAPIs(CardOpeningHistoryAPI);
@Component({})
export default class UserList extends Vue {
  private buyAddressApi: any = new CardOpeningHistoryAPI();
  private forms: any = {
    value: 0,
    input: "",
  };
  private form: any = {
    time: [], // 时间选择
  };

  private activated(): void {
    this.getTable();
  }
  private getTable(): void {
    this.buyAddressApi.shopOrder(
      {
        ...this.form,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
        this.tablePage.pageNo = res.data.page;
        this.tablePage.pageSize = res.data.prepage;
      }
    );
  }
  private options: any = [];
  private shopMember(): void {
    this.buyAddressApi.shopMember({}, (res: any) => {
      this.options = res.data.list;
    });
  }
  private addmembersshow: boolean = false;
  private addform = {};
  private handleClose() {
    this.addmembersshow = false;
    this.addform = new Util().clearObject(this.addform);
  }
  private addhis() {
    this.shopMember();
    this.addmembersshow = true;
  }
  private add_submit(): void {
    this.buyAddressApi.memberBuy(this.addform, (res: any) => {
      this.addmembersshow = false;
      this.forms = new Util().clearObject(this.forms);
      this.membuy = res.data;

      this.pay = true;
    });
  }
  private membuy: any = {};
  private addmembers(): void {
    this.addmembersshow = true;
  }

  private pay: boolean = false;
  private pay_type: any = [
    {
      check: 1,
      name: "微信",
      icon: require("@/assets/dialog/icon／微信@2x.png"),
    },
    {
      check: 2,
      name: "支付宝",
      icon: require("@/assets/dialog/icon／支付宝@2x.png"),
    },

    {
      check: 3,
      name: "线下收款码",
      icon: require("@/assets/dialog/icon／现金@2x.png"),
    },
    {
      check: 6,
      name: "现金",
      icon: require("@/assets/dialog/icon／现金@2x.png"),
    },
  ];
  private check: number = 1;
  private changePayType(index: number): void {
    this.check = index;
  }
  private scanCode: boolean = false;
  private scanCodePay(): void {
    this.scanCode = true;
    this.getscanCode();
  }
  private buy_ok = [200, 400220, 400213];
  private buy_two = [400217, 400218, 400214];
  private buy_no = [400211, 400212, 400215, 400216, 400219, 400221];
  private auth_code: string = "";
  private getscanCode(): void {
    let that = this;
    let str = "";
    document.onkeydown = function (e: any) {
      console.log(str);
      if (e.target.tagName !== "BODY") {
        str = "";
      } else {
        if (e.keyCode !== 13) {
          if (e.keyCode >= 32 && e.keyCode <= 126) {
            let k = e.key;
            str += k;
          }
        } else {
          if (str) {
            console.log(str);
            that.auth_code = str;
            str = "";
            that.payrequest();
          }
        }
      }
    };
  }
  private payrequest(): void {
    this.buyAddressApi.payrequest(
      {
        order_id: this.membuy.order_id,
        pay_type: this.check,
        biz_type: 5,
        auth_code: this.auth_code,
      },
      (res: any) => {
        if (this.buy_ok.includes(res.code)) {
          Util.showToast("支付成功");
          // this.renewal = false;
          // this.show_table = false;
          this.pay = false;
          this.scanCode = false;
          this.getTable();
        } else if (this.buy_two.includes(res.code)) {
          if (res.code == 400217) {
            Util.showToast("需要用户输入支付密码");
          } else if (res.code == 400218) {
            Util.showToast("付款码错误，请刷新二维码重试");
          } else if (res.code == 400214) {
            Util.showToast("支付失败，余额不足");
          }
        } else {
          this.scanCode = false;
          // this.pay=false
          // this.buygoods();
        }
      }
    );
  }
  private orderpay(): void {
    if (this.check === 3||this.check === 6) {
      this.buyAddressApi.payrequest(
        {
          order_id: this.membuy.order_id,
          pay_type: this.check,
          biz_type: 5,
        },
        () => {
          this.pay = false;
          this.addform = new Util().clearObject(this.addform);
          this.getTable();
          Util.showToast("开通成功");
        }
      );
    } else {
      this.scanCodePay();
    }
  }
  private payquery(): void {
    console.log(this.membuy);
    this.buyAddressApi.payquery(
      {
        order_id: this.membuy.order_id,
        biz_type: 5,
      },
      (res: any) => {
        if (this.buy_ok.includes(res.code)) {
          Util.showToast("支付成功");
          this.pay = false;
          this.scanCode = false;
          this.getTable();
        } else if (this.buy_two.includes(res.code)) {
          if (res.code == 400217) {
            Util.showToast("需要用户输入支付密码");
          } else if (res.code == 400218) {
            Util.showToast("付款码错误，请刷新二维码重试");
          } else if (res.code == 400214) {
            Util.showToast("支付失败，余额不足");
          }
        } else {
          this.scanCode = false;
          // this.pay=false
          // this.buygoods();
        }
      }
    );
  }
  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // channel渠道字段名 文案对应关系
  private merchantExcelList2ch: any = {
    order_no: "订单号",
    phone: "手机号",
    name: "会员卡名称",
    money: "充值金额",
    pay_time: "开通时间",
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "order_no",
    "phone",
    "name",
    "money",
    "pay_time",
  ];

  // 获取excelJSON数据
  private getExcelJSONDataByList(list: any[], sortKey: string[], map2ch: any) {
    const newList = list.map((eachItem) => {
      const newItem: any = {};

      for (const key of sortKey) {
        const val = eachItem[key];

        const chKey = map2ch[key];
        if (val) {
          newItem[chKey] = val;
        } else {
          newItem[chKey] = "";
        }
      }

      return newItem;
    });

    return newList;
  }

  // 点击导出excel 表格
  private handleExportExcelChannelList() {
    const form = this.form;
    const { time, channelId } = form;

    const params: any = {
      channelId,
    };

    if (Array.isArray(time) && time.length === 2) {
      params.startDate = Util.dateTimeUtilnohms(this.form.time[0]);
      params.endDate = Util.dateTimeUtilnohms(this.form.time[1]);
    } else {
      this.$message.error("请选择时段");

      return;
    }

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.buyAddressApi.exportOrder_shopOrder(
      { ...this.form },
      (res: any) => {
        const start_date: string = Util.dateUtil(this.form.time[0]);
        const end_date: string = Util.dateUtil(this.form.time[1]);
        const filename = `会员管理 ${start_date}到${end_date}.xlsx`;
        // const { list: originList } = res.data || {};
        const originList = res.data.list || [];

        // const {
        //   list: originList
        // } = data || {}

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              // const { orderNum } = item || {};
              const newItem = {
                ...(item || {}),
              };

              for (let key in item) {
                if (typeof item[key] == "number") {
                  newItem[key] = item[key].toString();
                }
              }

              // newItem.orderNum =
              //   typeof orderNum == "number"
              //     ? orderNum.toString()
              //     : orderNum || "";

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const merchantExcelSortList = this.merchantExcelSortList;

          const merchantExcelList2ch = this.merchantExcelList2ch;

          const excelJSONData = this.getExcelJSONDataByList(
            newList,
            merchantExcelSortList,
            merchantExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
